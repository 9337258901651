import React from "react";

import "./index.css";

import stripeLogo from "../../assets/icons/stripe-ar21.svg";

const Support = () => (
    <section className="support_container">
        <p className="support_text" >Падтрымайце нас</p>   
     <button className="support_button">
        <a className="support_link" href="https://donate.stripe.com/4gwaFK1gZ3G7efSdQQ" target="_blank" rel="noopener noreferrer">
            <img src={stripeLogo} alt="support_stripe" />    
        </a>  
    </button> 
    </section>
);

export default Support;