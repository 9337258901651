import zamova from "./images/zamova_afisha.png";
import how from "./images/how_are_you_afisha.jpg";
import more from "./images/more_afisha.jpg";
import galera from "./images/galera_afisha.png";

const projectsArr = [
    {
        name: "3@mova",
        image: zamova,
        author: "Максім Шышко",
        director: "Максім Шышко",
        premiere: "11.10.2022",
        music: "Мікіта Залатар",
        time: "45 хв",
        discription: `"Музычны праэкт, створаны па матывах песень і рытуалаў Палесся-тэрыторыі на мяжы Беларусі і Украіны.
        У тэксце выкарыстана замова – старажтны, знікаючы жанр народнай паэзіі Беларусі, якому здаўна прысвойваюць 
        містычныя якасці. 3@mova – спроба аднаўлення архаічных традыцыяў, прысвойванне ім якасцяў, уласцівых сучаснай лічбавай 
        рэальнасці. Стваральнікі імкнуцца праз гэты праэкт высветліць сваю сапраўдную ідэнтычнасць, сваю і гледача. 
        “Мы – пакаленне, якое стаіць на скрыжаванні эпох. Мы карыстаемся капутарамі і мабільнымі тэлефонамі, 
        і у той жа час мы чулі ад нашых дзядуль і бабуль старажытныя песні, паданні і фантыстычныя гісторыі з мінулых часоў. 
        3@mova – спроба аднаўлення і трансфармацыі вуснай народнай творчасці, пошук той формы фальклору, якая будзе цікавая нам, 
        а можа і нашым дзецям. З дапамогай тэатральных, мастацкіх сродкаў мы ствараем містычную, фантастычную атмасферу старажытных 
        абрадаў” - пішуць у эксплікацыі да праекту. Спектакль ідзе на беларускай мове і дыялектах Палесся, ён скіраваны адначасова 
        і да беарускай, украінскай дыяспары і да польскага гледача. Створаны пераклад на польскую і ангельскую мовы у выглядзе 
        мабільнага прыкладання."`
    },
    {
        name: "How are you?",
        image: how,
        author: "Віталь Карабань",
        director: "Андрэй Новік",
        premiere: "2022",
        music: "Дар'я Новік",
        time: "45 хв",
        discription: `„How are you” – дакументальны тэатральны праект пра вайну ва Украіне вачамі простых людзей.
        Тэкст базуецца на уласных перажываннях і сведчаннях людзей, вымушаных выжываць ва умовах вайны. 
        Гісторыі эвакуацыі з Кіева і Вінніцы, эміграцыі ў Румынію і Германію, аповедзь пра падзеі ў Бучы і Марыўпалі. 
        Галасы украінскіх і беларускіх герояў пераплятаюцца з каментарамі ресейца, які глядзіць расейскую прапаганду недзе ў расеі, 
        не ведаючы што яго ўласны сын гіне на крэйсеры “Масква” Стваральнікі вызначылі жанр праекту як “чытакль” – 
        спалучэнне спектаклю і сцэнічнага чытання, але на самой справе, гэта паунавартасны спектакль з мінімалістычнай сцэнаграфіяй, 
        якая складаецца з валізак, картонаў і спалучаецца з жывым выкананнем украінскіх народных песень. Спектакль паказваецца 
        ў падвалах альбо бамбасховішчах, каб максімальна прыблізіць досвед гледача да досведу герояў п’есы. У тэксце пералятаюцца 
        тры мовы: украінская, беларуская, расейская, адрозненні паміж імі размываюцца такім чынам, як быцам гэта адна мова. 
        Схухаючы гэтае спалучэнне моваў, разумееш, як шмат агульнага у народаў, якія цяпер раздзеленыя. Ад стваральнікаў: 
        „How Are You” распавядае гісторыі людзей, апынуўшыхся ў ваеннай рэальнасці. Нягледзячы ні на што, героі знаходзяць 
        сілы змагацца і дапамагаць адзін аднаму. Галоўнае – Жыць! Жыць, а не існаваць у рэаліях прапаганды. Аповедзь вядзецца 
        ад 24 лютага 2022 году і паказвае, як ва умовах вайны мяняецца успрыманне рэчаіснасці, калі чалавек паустае перад выбарам: 
        маўчаць ці гаварыць, бегчы ці застацца, думаць ці верыць тэлевізару.`, 
    },
    {
        name: "Мора Хрысціны",
        image: more,
        author: "Сяргей Гірыт",
        director: "Андрэй Новік",
        premiere: "04.07.2023",
        music: "Дар'я Новік",
        time: "85 хв",
        discription: `"Мора Хрысціны" - гэта п'еса аб падзеях 9-14 жніўня ў беларускіх РАУСах. Гвалт, 
        зверства і забойствы свайго народа, аб якіх мы не маем права забываць.
        Сёння, калі Украіна больш за год ахоплена вайной, якая забівае тысячы людзей, нельга забываць і аб іншай - 
        «ціхай» вайне ў Беларусі, якая доўжыцца вось ужо трэці год.
        Аб вайне дыктатара Лукашэнкі з уласным народам.
        Пра першыя дні пратэстаў у Беларусі ў 2020 годзе, з якіх пачалася гэтая вайна, распавядае новая беларуская п'еса 
        «Мора Хрысціны»
        Героі п'есы, якія выступілі супраць скрадзеных выбараў, і трапілі за краты, сутыкаюцца ў жорсткім канфлікце з сілавікамі - 
        слугамі дыктатара. Героі праходзяць праз жудасныя катаванні і здзекі, у той час, як для міліцыянераў гэта - 
        звычайны працоўны дзень.
        Дзень, у які беларускае грамадства раскалола глыбокая прорва`, 
    },
    {
        name: "Galera",
        image: galera,
        author: "Андрэй Іваноў",
        director: "Андрэй Новік",
        premiere: "3.12.2023",
        music: "Мікіта Залатар, Дар'я Новік",
        time: "50 хв",
        discription: `Тры чалавекі. 
        Адныя дзверы. 
        Што можа быць лягчэй - увайсці ды заявіць пра свае правы боссу, які не паважае цябе і фактычна валодае табой, як нявольнікам?
        Але, як высвятляць героі гэтага спектаклю, зрабіць гэта вельмі цяжка, амаль немагчыма.
        Чаму? 
        Можа, таму, што мы самі далі другім ўладу над сабой?
        Чаму мы самі робім з сябе слугаў кожную хвіліну?
        Мы - нявольнікі рэчаў, грошай, часу, палітыкаў, саміх сябе.
        Мы - гэта тры смешных ды трагічных герояў гэтага абсурдзістскага спектакля, якія невядома чаго чакаюць у прыемнай свайго шэфа.
        Уваходзьце у гэтыя дзверы, каб вырашыць для сябе: 
        жыцце - гэта вяселая ды леккая GRA
        ці - жудасная GALERA
        Праект створаны пры падтрымцы цэнтра дыялога Мірашэўскага.`, 
    }
]

export default projectsArr;