import React from "react";

import "./index.css";

const Header = () => (
    <section className="header_container">
        <div className="main_logo_container">
            <div className="main_logo">
            </div>
        </div>  
              
    </section>
);

export default Header;