import React from "react";

import "./index.css";

const Maininfo = () => {
    return (
        <div className="main_info_container">
            <p className="main_info_text">BY teatr – гэта тэатральнае бюро ў Варшаве, што аб'ядноўвае драматургаў, рэжысёраў, актораў, 
            мастакоў і іншых людзей тэатра, якія былі вымушаныя пакінуць Беларусь. Праекты BY teatr ствараюцца ў капрадукцыі 
            з польскімі партнёрамі ў іх прасторах – такая форма ўзаемадзеяння адпавядае прынцыпу мультыкультуралізму, 
            закладзенаму ў канцэпцыі праекта. 
            Захоўваючы свой твар, беларускую ідэнтычнасць і мову, мы ўступаем у дыялог з культурай краіны, якая нас прыняла. 
            Усе праекты ствараюцца на аснове сучаснай драматургіі Беларусі. Абавязковая частка праекту – стварэнне базы 
            тэкстаў сучаснай беларускай драматургіі і яе перакладаў на замежныя мовы. 
            Мы спадзяемся, што BY teatr стане цэнтрам вольнай сучаснай беларускай культуры, аб'яднае экспатаў з Беларусі і ўсіх, 
            каму можа быць цікавая беларуская культура за мяжой.</p>
        </div>
    )
   
};

export default Maininfo;