import './App.css';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Support from './components/Support/Support';
import Maininfo from './components/Maininfo/Maininfo';
import SliderComand from './components/SliderComand/SliderComand';
import SliderProjects from './components/SliderProjects/SliderProjects';

import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <div className="App">
      <div className="app-body">
        <div className='container header_container'>
          <Header/>
        </div>
        <Support/>
        <Maininfo/>
        <SliderProjects/>
        <SliderComand/>
        <Footer/>
        </div>
    </div>
  );
}

export default App;
