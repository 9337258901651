import maksym from "../../assets/images/max_photo.jpg";
import andrey from "../../assets/images/andrey_n_photo.jpg";
import dasha from "../../assets/images/dasha_photo.jpg";
import masha from "../../assets/images/masha_photo.jpg";
import lera from "../../assets/images/lera_photo.jpg"
import vlad from "../../assets/images/vlad_photo.jpg";
import mikita from "../../assets/images/mikita_photo.jpg";
import lesha from "../../assets/images/lesha_photo.png";

const comand = [
    {
        name: "Максім",
        surname: "Шышко",
        photo: maksym,
        profession: "актор, рэжысёр",
        bio: `Нарадзіўся ва Украіне, у 1999 з сям’ёй пераехаў у Беларусь, дзе жыў і працаваў да 2020 году. 
        У 2020 звольніўся з Рэспубліканскага Тэатру Беларускай Драматургіі ў знак салідарнасці з трупай тэатру ім. Янкі Купалы. 
        У 2022 быў арыштаваны за адзіночны пікет супраць вайны ва Украіне і прымусова дэпартаваны з Беларусі 
        з забаронай уезду на 10 гадоў. Скончыў Брэсцкі дзяржаўны музычны каледж (акадэмічны спеў), 
        Беларускую дзяржаўную акадэмію мастацтваў, тэатральны ф-т, спецыяльнасць –«Актор тэатра і кіно». 
        Рэжысёр спектакля “3@mova”. За кар’еру больш 30 тэатральных роляў і каля 15 роляў у кіно. `,
        mail: `mailto: atenobimailru@gmail.com`,
        insta: "https://www.instagram.com/atenobi",
        face: "https://www.facebook.com/atenobi",
    },
    {
        name: "Андрэй",
        surname: "Новік",
        photo: andrey,
        profession: "актор, рэжысёр",
        bio: `Скончыў Беларускую дзяржаўную акадэмію мастацтваў, тэатральны ф-т, спецыяльнасць – майстэрства актора. 
        Актор Рэспубліканскага тэатра беларускай драматургіі ў Менску. За кар’еру больш 30 тэатральных роляў 
        і каля 40 роляў у кіно. Звольніўся з тэатру ў знак пратэсту супраць палітычнага пераследу ў іншых тэатрах Беларусі 
        і ў знак салідарнасці са звольненай трупай тэатру імя Янкі Купалы. З 2021 жыве і працуе у Варшаве. `,
        mail: `mailto: andrei.novik89@mail.ru`,
        insta: "https://www.instagram.com/novik732/",
        face: "https://www.facebook.com/profile.php?id=100006150283610",
    },
    {
        name: "Дар'я",
        surname: "Новік",
        photo: dasha,
        profession: "акторка, кампазітар, спявачка",
        bio: `У 2009 годзе скончыла Дзяржаўны каледж мастацтваў ім. Н.Ф. Сакалоўскага па спецыяльнасці “харавое дырыжыраванне”. 
        З 2011 году працавала ў Беларускім дзяржаўным музычным тэатры, сумяшчаючы працу і адукацыю. У 2013 годзе скончыла 
        Беларускую дзяржаўную акадэмію мастацтваў, тэатральны ф-т, аддзяленне – “актор музычнага тэатру”. У тым жа годзе 
        пачала працаваць у Нацыянальным Акадэмічным Вялікім Тэатры оперы і балета Беларусі. Летам 2021 годабыла вымушаная выехаць 
        з Беларусі па палітычных матывах у Польшчу разам з мужам, звольненым з тэатру ў выніку рэпрэссій.`,
        mail: `mailto: novatorsha3500@gmail.com`,
        insta: "https://www.instagram.com/volojina18121989/",
        face: "https://www.facebook.com/profile.php?id=100005170000178",
    },
    {
        name: "Марыя",
        surname: "Пятровіч",
        photo: masha,
        profession: "акторка, спявачка",
        bio: `Скончыла Беларускі дзяржаўны унівэрсітэт культуры і мастацтваў па спецыяльнасці “рэжысёр, педагог спецыяльных дысцыплін, 
        арганізатар тэатральнай дзейнасці”, 
        Беларускую дзяржаўную акадэмію мастацтваў, тэатральны ф-т, спецыяльнасць –“актор тэатру і кіно”. З 2007 па 2022 год – 
        акторка Рэспубліканскага тэатру беларускай драматургіі ў Менску. У 2022 звольненая па палітычных матывах. Працавала таксама 
        на незалежных тэатральных пляцоўках: “Альфа-тэатр”, “ОК-16”. Сыграла 35 роляў у тэатры і 20 роляў у кіно. З 2022 жыве 
        і працуе у Варшаве. `,
        mail: `mailto: masha_petr84@mail.ru`,
        insta: "https://www.instagram.com/masha_pietrovich/",
        face: "https://www.facebook.com/masha.petr",
    },
    {
        name: "Валерыя",
        surname: "Сігова",
        photo: lera,
        profession: "акторка",
        bio: `Нарадзілася ва Украіне, у горазде Херсон у 1992 годзе.         
        У 2016 скончыла Кіеўскі нацыянальны універсітэт культуры і мастацтваў па спецыяльнасці "Акцёр драматычнага тэатру" 
        (майстэрня Пятра Ільчэнкі і Кацярыны Піваваравай).
        Працавала ў Херсонскім абласным акажэмічным музычна-драматычным тэатры імя Мікалая Куліша.
        У 2019 годзе пераехала ў Мінск (Беларусь). З 2022 году жыве і працуе ў горадзе Варшава (Польша).`,
        insta: "https://www.instagram.com/sigovalera/",
        face: "https://www.facebook.com/sigovalera",
    },
    {
        name: "Мікіта",
        surname: "Залатар",
        photo: mikita,
        profession: "кампазітар, актор",
        bio: `У 2014 скончыў БДАМ як акцёр.  Да гэтага скончыў музычную школу па класе акардэона.  
        Працаваў у РТБД, Тэрыторыі Мюзікла і Korniag Theatr у якасці акцёра, кампазітара і тэхніка.  
        Пісаў музыку да спектакляў у краінах СНД і ЕС.  
        На сёння з'яўляецца акцёрам тэатра Agora у Бельгіі.`,
        insta: "https://www.instagram.com/zolotar_nikita/",
        face: "https://www.facebook.com/profile.php?id=100002163693757",
    },
    {
        name: "Уладзіслаў",
        surname: "Федзюковіч",
        photo: vlad,
        profession: "актор, прадпрымальнік",
        bio: `Нарадзіўся ў горадзе Пінск Рэспубліка Беларусь.
        Да 18 гадоў жыў у Беларусі, дзе вучыўся ў акадэміі МУС. 
        Пасля пераехаў у Польшчу, дзе і жыў апошнія 6 гадоў.
        У Варшаве скончыў курс акторскай майстэрства "Квадрат", пад кіраўніцтвам Макіма Шышко.
        Дэбютная роля - Вася "Мора Хрысціны" рэж. А.Новік.`,
        insta: "https://www.instagram.com/diadiafi0d0r/",
    },
    {
        name: "Аляксей",
        surname: "Сапрыкін",
        photo: lesha,
        profession: "актор",
        bio: `У 2017 годзе скончыў Еўрапейскі гуманітарны ўніверсітэт па спецыяльнасці
        “Візуальная культура (кіно, тэлевізія, інтэрнэт)”, а таксама тэатральную студыю Fortinbras пры Беларускім свабодным тэатры. 
        У 2015 годзе ўдзельнічаў у тэатральным абмене Erasmus + у горадзе Эслов (Швэцыя). Граў у БСТ з 2016 па 2022 год
        У 2019 годзе ўвайшоў у “топ 10 маладых актораў за якімі варта сачыць” па версіі інтэрнэт-часопіса 34mag.net 
        З 2022 года жыве і працуе ў Польшчы.`,
        insta: "https://www.instagram.com/aleks.saprykin/",
        face: "https://www.facebook.com/mielasz",
    },
];

export default comand;